import React, { useEffect, useState } from "react";

import Hamburger from "@urbanpiper-engineering/meraki-components/dist/Atoms/Hamburger";
import SearchCta from "@urbanpiper-engineering/meraki-components/dist/Molecules/SearchCta";
import Typography from "@urbanpiper-engineering/meraki-components/dist/Atoms/Typography";
import LanguageWidget from "@urbanpiper-engineering/meraki-components/dist/Molecules/LanguageWidget";
import NavDrawer from "@urbanpiper-engineering/meraki-components/dist/Cells/NavDrawer";
import Header from "@urbanpiper-engineering/meraki-components/dist/Cells/Header";
import HeaderLogo from "@urbanpiper-engineering/meraki-components/dist/Atoms/HeaderLogo";
import BottomNav from "@urbanpiper-engineering/meraki-components/dist/Cells/BottomNav";
import FloatingStack from "@urbanpiper-engineering/meraki-components/dist/Atoms/FloatingStack";
import useConfirm from "@urbanpiper-engineering/meraki-components/dist/Molecules/Confirm";
import configSelector from "../../external/configSelector";
import MerakiSDK from "@urbanpiper-engineering/meraki-sdk";

import { useRouter } from "next/router";
import ScreenHelper from "../../external/helpers/screenHelper";
import dynamic from "next/dynamic";
import { CartState } from "meraki-sdk/dist/esm/cart/states/cart.state";
import { useIsMobile } from "../../hooks/is_mobile.hook";
import { UserState } from "meraki-sdk/dist/esm/user/states/user.state";
import SideDrawerHelper from "../../external/helpers/sideDrawer.helper";
import deepClone from "../../external/helpers/deepClone";

import styles from "./header.module.scss";
import pageSelector from "../../external/pageSelector";
import { useTranslation } from "react-i18next";
import Image from "next/image";

const LocationSelector = dynamic(() => import("../location_selector"), {
  ssr: false,
});
const Login = dynamic(() => import("../login"), { ssr: false });
const ViewCart = dynamic(
  () =>
    import("@urbanpiper-engineering/meraki-components/dist/Molecules/ViewCart"),
  { ssr: false }
);

const { HeaderBase, HeaderLeft, HeaderRight } = Header;

function HeaderComponent({
  webBgImg,
  supportedLanguages,
  businessInfo,
  referralInfo,
  locale,
}: {
  webBgImg: string;
  supportedLanguages: string[];
  businessInfo: any;
  referralInfo: any;
  locale: string;
}) {
  const router = useRouter();

  const isMobileView = useIsMobile();
  const [menuLinks, setMenuLinks] = useState(SideDrawerHelper.getLinks());

  const useCartState = MerakiSDK.getCartState().hook();
  const useUserState = MerakiSDK.getUserState().hook();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const isFFSelectorVisible = ScreenHelper.isFFSelectorVisible(router.pathname);

  const isSearchVisible = ScreenHelper.isSearchVisible(router.pathname);
  const isLandingPage = ScreenHelper.isLandingPage(router.pathname);
  const isSearchPage = ScreenHelper.isSearchPage(router.pathname);
  const isCheckoutPage = ScreenHelper.isCheckoutPage(router.pathname);
  const isPaymentOptionsPage = ScreenHelper.isPaymentOptionsPage(
    router.pathname
  );

  const userData = useUserState((state: typeof UserState) => state.user);
  const activeLanguageInState = useUserState(
    (state: typeof UserState) => state.language
  );
  const setLanguage = useUserState(
    (state: typeof UserState) => state.setLanguage
  );

  const cartCount = useCartState(
    (state: typeof CartState) => state.cart?.item_count
  );

  const logo = configSelector.getBizLogoURL();
  const mobileLogo = configSelector.getBizSquareLogoURLMobile();
  const { showItemSearch } = pageSelector.getMenuPage().customizations;
  const defaultLanguage = locale || configSelector.getDefaultLanguage();

  const setCookie = (locale: string) => {
    document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000;path=/`;
  };

  const getUserDetails = (userData: any) => {
    if (userData) {
      return {
        name: [userData?.first_name, userData?.last_name].join(" "),
        phone: userData?.phone,
        is_guest_checkout: userData?.is_guest_checkout,
      };
    }
    return null;
  };

  useEffect(() => {
    if (openDrawer) {
      setOpenDialog(false);
    }
  }, [openDrawer]);

  useEffect(() => {
    if (activeLanguageInState) {
      setLanguage(defaultLanguage);
      setCookie(defaultLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage]);

  useEffect(() => {
    if (openDialog) {
      setOpenDrawer(false);
    }
  }, [openDialog]);

  useEffect(() => {
    if (!menuLinks) return;
    const menuLinksClone = deepClone(menuLinks);
    if (userData && !userData?.is_guest_checkout) {
      menuLinksClone.forEach((links: any) => {
        links.isEnabled = true;
      });
    } else {
      menuLinksClone.forEach((links: any) => {
        if (links.isProtected) {
          links.isEnabled = false;
        }

        // show logout option for guest user
        if (
          links.label === "Logout" &&
          links.isProtected &&
          userData?.is_guest_checkout
        ) {
          links.isEnabled = true;
        }
      });
    }
    setMenuLinks(menuLinksClone);

    updateFreshChatUserInfo(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const updateFreshChatUserInfo = async (userData: any) => {
    const freshChatService = await MerakiSDK.getFreshChat();
    if (userData) {
      const userDetails = {
        firstName: userData?.first_name || "",
        lastName: userData?.last_name || "",
        phone: userData?.phone || "",
        email: userData?.email || "",
      };
      freshChatService.updateInfo(userDetails);
    } else {
      freshChatService.updateInfo({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      });
    }
  };

  const supportedLanguagesArr = supportedLanguages?.map((lang: string) => ({
    code: lang,
    name: lang.toUpperCase(),
  }));

  const updateLocalLanguage = async (lang: string) => {
    setLanguage(lang);
    setCookie(lang);

    const freshChatService = await MerakiSDK.getFreshChat();
    freshChatService.updateLanguage(lang);
    router.reload();
  };

  const [backButton, setBackButton] = useState(false);

  useEffect(() => {
    setBackButton(!ScreenHelper.isMenuPage(router.pathname));
  }, [router.pathname]);

  useEffect(() => {
    if (activeLanguageInState === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [activeLanguageInState]);

  const { t } = useTranslation();
  const confirmationDialog = useConfirm();

  const handleLogoutClick = async () => {
    setOpenDrawer(false);
    confirmationDialog({
      title: t("common.logoutAlertTitle"),
      content: t("common.logoutAlert"),
      removeCloseIcon: false,
      confirmText: t("header.logout"),
      variant: "delete",
      onConfirm: async () => {
        await MerakiSDK.logout();
        router.push("/menu");
      },
    });
  };

  /**
   * Todo
   * 1. Handle this in the header itself
   */
  if (isMobileView && isSearchPage) {
    return <LocationSelector hideLocationSelectorHeading />;
  }

  if (isLandingPage) {
    return;
  }

  return (
    <HeaderBase classes={styles.header}>
      <HeaderLeft>
        {backButton}
        <Hamburger
          onClick={() => {
            if (backButton) {
              //to avoid query history stack, specific category scroll
              if (isSearchPage) {
                return router.push("/menu");
              }

              return router.back();
            }
            setOpenDrawer(true);
          }}
          backButton={backButton}
        />

        <NavDrawer
          classes={styles.navDrawer}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          user={getUserDetails(userData)}
          onLoginCallback={() => setOpenDialog(true)}
          menuLinks={menuLinks}
          onLogoutCallback={handleLogoutClick}
          onEditProfileCallback={() => {
            setOpenDrawer(false);
            router.push("/profile");
          }}
        />
        {!(backButton && isMobileView) && (
          <a
            className={styles.logoWrapper}
            onClick={() => router.push("/menu")}
          >
            <HeaderLogo
              imageComponent={Image}
              webLogo={logo}
              mobileLogo={mobileLogo}
              isMobile={isMobileView}
            />
          </a>
        )}
        {isFFSelectorVisible && <LocationSelector />}
      </HeaderLeft>

      {isMobileView && backButton ? (
        <Typography classes={styles.mobileHeaderTitle} variant="headingTwo">
          {ScreenHelper.getTitle(router.pathname, router.asPath)}
        </Typography>
      ) : null}

      <HeaderRight>
        {isSearchVisible && showItemSearch && (
          <SearchCta
            isActive={isSearchPage}
            isMobile={isMobileView}
            onClick={() => router.push("/search")}
          />
        )}
        {supportedLanguages?.length > 1 ? (
          <LanguageWidget
            onLanguageChange={(lang: string) => updateLocalLanguage(lang)}
            activeLanguage={
              activeLanguageInState || supportedLanguagesArr[0].code
            }
            languages={supportedLanguagesArr}
            isMobile={isMobileView}
          />
        ) : null}
        <Login
          webBgImg={webBgImg}
          isMobile={isMobileView}
          openDialog={openDialog}
          currency={businessInfo?.currency}
          isdCode={businessInfo?.isd_code}
          referralInfo={referralInfo}
        />

        {!isMobileView && (
          <a onClick={() => cartCount > 0 && router.push("/checkout")}>
            <ViewCart numberOfCartItems={cartCount} isMobile={isMobileView} />
          </a>
        )}
      </HeaderRight>
      {isMobileView && !isPaymentOptionsPage && !isCheckoutPage ? (
        <FloatingStack order={-1}>
          <BottomNav
            items={[
              {
                type: "home",
                label: "home",
                link: "/menu",
              },
              {
                type: "offers",
                label: "offers",
                link: "/offers",
                disabled: userData?.is_guest_checkout,
              },
              {
                type: "orders",
                label: "orders",
                link: "/orders",
                disabled: userData?.is_guest_checkout,
              },
              {
                type: "profile",
                title: userData?.first_name,
                link: "/profile",
                label: "profile",
              },
            ]}
            activePath={router.pathname}
            onNavUpdate={(path: string) => {
              if (path === "/profile") {
                setOpenDrawer(true);
              } else {
                router.push(path);
              }
            }}
          />
        </FloatingStack>
      ) : null}
    </HeaderBase>
  );
}

export default HeaderComponent;
