import pageSelector from "../pageSelector";
import configSelector from "../configSelector";

export default class SideDrawerHelper {
  static getLinks() {
    const sideDrawerLinks = [];
    const menuPageConfig = pageSelector.getMenuPage();
    const orderHistoryPageConfig = pageSelector.getOrderHistoryPage();
    const favouritesPageConfig = pageSelector.getFavouritesPage();
    const referAndEarnPageConfig = pageSelector.getReferEarnPage();
    const profilePageConfig = pageSelector.getProfilePage();
    const couponsPageConfig = pageSelector.getCouponsPage();
    const walletPageConfig = pageSelector.getWalletPage();
    const customPages = configSelector.getCustomPages();

    if (menuPageConfig?.enabled) {
      const menuLink = {
        label: menuPageConfig.name,
        link: "/menu",
        isProtected: false,
        isEnabled: true,
      };
      sideDrawerLinks.push(menuLink);
    }

    if (orderHistoryPageConfig?.enabled) {
      const orderHistoryLink = {
        label: orderHistoryPageConfig.name,
        link: "/orders",
        isProtected: true,
        isEnabled: false,
      };
      sideDrawerLinks.push(orderHistoryLink);
    }

    if (favouritesPageConfig?.enabled) {
      const favouriteLink = {
        label: favouritesPageConfig.name,
        link: "/favourites",
        isProtected: true,
        isEnabled: false,
      };
      sideDrawerLinks.push(favouriteLink);
    }

    if (referAndEarnPageConfig?.enabled) {
      const referAndEarnLink = {
        label: referAndEarnPageConfig.name,
        link: "/refer",
        isProtected: true,
        isEnabled: false,
      };
      sideDrawerLinks.push(referAndEarnLink);
    }

    if (profilePageConfig?.enabled) {
      // const profilePageLink = {
      //   label: profilePageConfig.name,
      //   link: "/profile",
      //   isProtected: true,
      //   isEnabled: false,
      // };
      //sideDrawerLinks.push(profilePageLink);

      const manageAddressLink = {
        label: "Manage Address",
        link: "/address",
        isProtected: true,
        isEnabled: false,
      };

      sideDrawerLinks.push(manageAddressLink);
    }

    if (couponsPageConfig?.enabled) {
      const couponsPageLink = {
        label: couponsPageConfig.name,
        link: "/offers",
        isProtected: false,
        isEnabled: true,
      };
      sideDrawerLinks.push(couponsPageLink);
    }

    if (walletPageConfig?.enabled) {
      const walletPageLink = {
        label: walletPageConfig.name,
        link: "/wallet",
        isProtected: true,
        isEnabled: false,
      };
      sideDrawerLinks.push(walletPageLink);
    }

    customPages?.forEach((customPage) => {
      const tempObj = {
        label: customPage.name,
        link: customPage.path,
        isProtected: false,
        isEnabled: true,
      };

      sideDrawerLinks.push(tempObj);
    });
    const logoutLink = {
      label: "Logout",
      link: "/logout",
      isProtected: true,
      isEnabled: true,
    };
    sideDrawerLinks.push(logoutLink);

    return sideDrawerLinks;
  }
}
